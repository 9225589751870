import axios from 'axios';
import { BASE_API_URL } from 'common/const.common';
import { IGetByIdentificationUser } from '../interfaces/user.interface';

import { authService } from 'services/auth/authService';

const MODULE_URL = BASE_API_URL + '/users';

export const getByIdentificationUser = async (
  identification: string,
): Promise<any> => {
  try {
    const TOKEN = authService.getTokenAPIInterna();
    const URL = `${MODULE_URL}?identification=${identification}`;

    const resp = await fetch(URL, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    });

    const respJson = await resp.json();
    const Dados = respJson?.data[0];

    localStorage.setItem('userAccessUuid', Dados.uuid);
    localStorage.setItem('institutionUuid', Dados.institution?.uuid);
    localStorage.setItem('position', Dados.position);
    localStorage.setItem('profileUuid', Dados.profile?.uuid);

    // console.log('Dados : ' + JSON.stringify(Dados));

    return Dados as IGetByIdentificationUser;
  } catch (error) {
    return {
      accessToken: null,
      error: {
        error: 'fetch_error',
        error_description:
          'Ocorreu um erro ao tentar obter o token da API Interna. Por favor, verifique sua conexão ou tente novamente mais tarde.',
      },
    };
  }
};
