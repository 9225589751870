import React, { useState } from 'react';
import { TopNavBar } from 'components/TopNavBar';
import { Box } from '@mui/system';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
} from '@mui/material';
import { partialUpdateUser } from 'api/user/user.api';
import { getLogoutMtLogin } from 'services/auth/apiService';
import { ErrorDialog } from 'components/Shared';
import { ROUTES } from 'routes/constants';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/auth/AuthContext';

const ContentBox = styled(Box)({
  backgroundColor: '#FFFFFF',
  maxWidth: '975px',
  marginTop: '30px',
  borderRadius: '8px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',

  marginLeft: 'auto',
  marginRight: 'auto',
  height: '530px',
  overflowY: 'auto',
});

const TermsOfUseAndConfidentiality: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [isChecked, setIsChecked] = useState(false);
  const [openAgreementRequiredDialog, setOpenAgreementRequiredDialog] =
    useState(false);
  const [invalidRequest, setInvalidRequest] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleExitAndNavigateLogin = () => {
    getLogoutMtLogin();
  };

  const handleAgreeAndContinue = async () => {
    if (isChecked) {
      if (user && user.uuid) {
        try {
          await partialUpdateUser(user.uuid, { hasAcceptedTerms: true });
          navigate(ROUTES.HOME);
        } catch (error) {
          setInvalidRequest(true);
        }
      } else {
        alert('Usuário não encontrado na aplicação');
        console.log('user : ' + JSON.stringify(user));
      }
    } else {
      setOpenAgreementRequiredDialog(true);
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <>
      <TopNavBar />
      <Box
        sx={{
          backgroundColor: '#0048B6',
          height: '48px',
          alignContent: 'center',
        }}
      >
        <Typography variant='h6' sx={{ color: '#FFFFFF' }}>
          Aceite de termo de uso
        </Typography>
      </Box>
      <Box sx={{ marginTop: '48px' }}>
        <Typography variant='h5'>Termo de uso e confidencialidade</Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '22.88px',
            letterSpacing: '0.17px',
            marginTop: '20px',
          }}
        >
          Leia atentamente e concorde com o termo de uso e confidencialidade
          para que possa realizar o acesso ao sistema.
        </Typography>
      </Box>
      <ContentBox>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '24px',
            letterSpacing: '0.15px',
            marginTop: '20px',
          }}
        >
          TERMO DE USO E CONFIDENCIALIDADE
        </Typography>

        <Box sx={{ marginTop: '10px', padding: '20px' }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              marginBottom: '10px',
            }}
          >
            Eu assumo o compromisso de manter confidencialidade e sigilo sobre
            todas as informações a que tiver acesso como membro da Comissão de
            Acolhimento e Orientação da SEAF, instaurada nos termos da Portaria
            Interna SEAF, de 10 de outubro de 2016.
          </Typography>

          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              marginBottom: '10px',
            }}
          >
            Por este termo de confidencialidade e sigilo comprometo-me:
          </Typography>

          <Typography
            component='div'
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              marginBottom: '10px',
            }}
          >
            <ol style={{ paddingLeft: '20px', margin: 0 }}>
              <li style={{ marginBottom: '10px' }}>
                A não utilizar as informações confidenciais a que tiver acesso,
                para gerar benefício próprio exclusivo e/ou unilateral, presente
                ou futuro, ou para o uso de terceiros;
              </li>
              <li style={{ marginBottom: '10px' }}>
                A não efetuar nenhuma gravação ou cópia da documentação
                confidencial a que tiver acesso;
              </li>
              <li style={{ marginBottom: '10px' }}>
                A não me apropriar de material confidencial e/ou sigiloso que
                venha a ser disponibilizado;
              </li>
              <li style={{ marginBottom: '10px' }}>
                A não repassar o conhecimento das informações confidenciais,
                responsabilizando-me por todas as pessoas que vierem a ter
                acesso às informações, por meu intermédio, e obrigando-me,
                assim, a ressarcir a ocorrência de qualquer dano e/ou prejuízo
                oriundo de uma eventual quebra de sigilo das informações
                fornecidas.
              </li>
            </ol>
          </Typography>

          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              marginBottom: '10px',
            }}
          >
            Neste Termo, as seguintes expressões serão assim definidas:
            Informação Confidencial significará toda informação revelada sob a
            forma escrita, verbal ou por quaisquer outros meios.
          </Typography>

          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              marginBottom: '10px',
            }}
          >
            Informação Confidencial inclui, mas não se limita, à informação
            sobre as questões relativas ao propósito da criação da Comissão de
            Acolhimento e Orientação, de acordo com a Portaria Interna SEAF, de
            10 de outubro de 2016.
          </Typography>

          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              marginBottom: '10px',
            }}
          >
            Pelo não cumprimento do presente Termo de Confidencialidade e
            Sigilo, fica o abaixo assinado ciente de todas as sanções judiciais
            que poderão advir.
          </Typography>
        </Box>
      </ContentBox>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '15px',
          maxWidth: '975px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: '25.74px',
            letterSpacing: '0.17px',
            textAlign: 'left',
          }}
        >
          Confirmo que li todo o termo de uso e confidencialidade e concordo com
          todo seu conteúdo.
        </Typography>
      </Box>
      <Box
        sx={{
          justifyContent: 'center',
          marginTop: '15px',
        }}
      >
        <Button
          variant='text'
          color='primary'
          size='medium'
          onClick={handleExitAndNavigateLogin}
        >
          SAIR
        </Button>
        <Button
          variant='contained'
          onClick={handleAgreeAndContinue}
          sx={{
            backgroundColor: !isChecked ? '#d3d3d3' : '',
          }}
        >
          ACEITAR
        </Button>
      </Box>

      <Dialog
        open={openAgreementRequiredDialog}
        onClose={() => setOpenAgreementRequiredDialog(false)}
        maxWidth='lg'
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            padding: '24px',
            boxSizing: 'border-box',
            height: 'auto',
          },
        }}
      >
        <DialogTitle>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '38.4px',
              letterSpacing: '0.15px',
              textAlign: 'left',
            }}
          >
            Confirmação de aceite dos termos de uso
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '27px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              marginBottom: '16px',
            }}
          >
            Você ainda não realizou a confirmação de leitura e aceite dos termos
            de uso.
          </Typography>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '27px',
              letterSpacing: '0.15px',
              textAlign: 'left',
            }}
          >
            Marque a caixa “Confirmo que li todo o termo de uso e
            confidencialidade e concordo com todo seu conteúdo” e clique em
            “Aceitar” para prosseguir com seu acesso ao sistema.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <Button
            onClick={() => setOpenAgreementRequiredDialog(false)}
            autoFocus
          >
            VOLTAR
          </Button>
        </DialogActions>
      </Dialog>

      <ErrorDialog
        open={invalidRequest}
        title='Ocorreu um erro na sua solicitação'
        message1='Deseja atualizar a página atual?'
        button1Text='FECHAR'
        button1Action={handleExitAndNavigateLogin}
        button1Color='inherit'
        button2Text='ATUALIZAR'
        button2Action={handleRefresh}
        button2Color='primary'
      />
    </>
  );
};

export default TermsOfUseAndConfidentiality;
