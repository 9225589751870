import React, { useCallback, useEffect, useState } from 'react';
import { Loading } from '../../components/Login';
import { ErrorDialog } from 'components/Shared';
import { AuthStatus, useAuth } from 'contexts/auth/AuthContext';
import { authService } from 'services/auth/authService';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

type DialogButtonConfig = {
  text: string;
  action: () => void;
  color:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
};

type ErrorDialogState = {
  open: boolean;
  title: string;
  message1: string;
  message2?: string | null;
  button1: DialogButtonConfig;
  button2?: DialogButtonConfig | null;
};

const LoginValidation: React.FC = () => {
  const navigate = useNavigate();
  const { authenticateWithMtLogin, logout } = useAuth();

  const [errorDialogState, setErrorDialogState] = useState<ErrorDialogState>({
    open: false,
    title: '',
    message1: '',
    button1: {
      text: '',
      action: () => {},
      color: 'inherit',
    },
    button2: null,
  });

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleSetSessionExpiredDialog = useCallback(() => {
    setErrorDialogState({
      open: true,
      title: 'Sua sessão expirou',
      message1:
        'Por segurança a sessão expirou. Você deve voltar para a tela inicial e entrar novamente.',
      button1: {
        text: 'FECHAR',
        action() {
          logout();
        },
        color: 'primary',
      },
    });
  }, [logout]);

  const handleSetUserBlockedDialog = useCallback(() => {
    setErrorDialogState({
      open: true,
      title: 'Usuário Bloqueado',
      message1: 'O acesso do Usuário informado encontra-se bloqueado.',
      message2: `Orientamos que entre em contato com a Administração do sistema para realizar o desbloqueio do Usuário, ou Informe outro
        Usuário para realizar o acesso na página anterior.`,

      button1: {
        text: 'voltar',
        action() {
          logout();
        },
        color: 'primary',
      },
    });
  }, [logout]);

  const handleSetUserInactiveDialog = useCallback(() => {
    setErrorDialogState({
      open: true,
      title: 'Usuário Inativo',
      message1: 'O acesso do Usuário informado não está ativo.',
      message2: `Orientamos que entre em contato com a Administração do sistema para verificar o status de acesso deste Usuário,
        ou Informe outro Usuário para realizar o acesso na página anterior.`,

      button1: {
        text: 'voltar',
        action() {
          logout();
        },
        color: 'primary',
      },
    });
  }, [logout]);

  const handleSetUserNotFoundDialog = useCallback(() => {
    setErrorDialogState({
      open: true,
      title: 'Usuário Não Encontrado',
      message1: 'O acesso do Usuário informado não foi localizado.',
      message2: `Orientamos que entre em contato com a Administração do sistema para verificar o status de acesso deste Usuário,
        ou Informe outro Usuário para realizar o acesso na página anterior.`,

      button1: {
        text: 'voltar',
        action() {
          logout();
        },
        color: 'primary',
      },
    });
  }, [logout]);

  const handleSetRequestErrorDialog = useCallback(() => {
    setErrorDialogState({
      open: true,
      title: 'Ocorreu um erro na sua solicitação',
      message1: 'Deseja atualizar a página atual?',
      button1: {
        text: 'fechar',
        action() {
          logout();
        },
        color: 'inherit',
      },
      button2: {
        text: 'atualizar',
        action() {
          handleRefresh();
        },
        color: 'primary',
      },
    });
  }, [logout]);

  useEffect(() => {
    const validateLogin = async () => {
      const codeFromUrl = authService.getCodeFromUrl();

      if (!codeFromUrl) {
        handleSetSessionExpiredDialog();
        return;
      }

      const resultAuthenticateWithMtLogin = await authenticateWithMtLogin(
        codeFromUrl,
      );

      console.log(
        'resultAuthenticateWithMtLogin',
        JSON.stringify(resultAuthenticateWithMtLogin),
      );

      if (!resultAuthenticateWithMtLogin.success) {
        if (
          resultAuthenticateWithMtLogin.status === AuthStatus.sessionExpired
        ) {
          handleSetSessionExpiredDialog();
          return;
        }
        if (resultAuthenticateWithMtLogin.status === AuthStatus.userBlocked) {
          handleSetUserBlockedDialog();
          return;
        }
        if (resultAuthenticateWithMtLogin.status === AuthStatus.userInactive) {
          handleSetUserInactiveDialog();
          return;
        }
        if (resultAuthenticateWithMtLogin.status === AuthStatus.userNotFound) {
          handleSetUserNotFoundDialog();
          return;
        }

        if (resultAuthenticateWithMtLogin.status === AuthStatus.requestError) {
          handleSetRequestErrorDialog();
          return;
        }
      } else {
        if (
          resultAuthenticateWithMtLogin.status === AuthStatus.userAcceptTerms
        ) {
          navigate(ROUTES.TERMS_OF_USE_AND_CONFIDENTIALITY);
        }
        if (
          resultAuthenticateWithMtLogin.status === AuthStatus.userAuthorized
        ) {
          navigate(ROUTES.HOME);
        }
      }
    };

    validateLogin();
  }, [
    authenticateWithMtLogin,
    handleSetRequestErrorDialog,
    handleSetSessionExpiredDialog,
    handleSetUserBlockedDialog,
    handleSetUserInactiveDialog,
    handleSetUserNotFoundDialog,
    navigate,
  ]);

  return (
    <>
      <Loading />

      <ErrorDialog
        open={errorDialogState.open}
        title={errorDialogState.title}
        message1={errorDialogState.message1}
        {...(errorDialogState.message2 && {
          message2: errorDialogState.message2,
        })}
        button1Text={errorDialogState.button1.text}
        button1Action={errorDialogState.button1.action}
        button1Color={errorDialogState.button1.color}
        {...(errorDialogState.button2 && {
          button2Text: errorDialogState.button2.text,
          button2Action: errorDialogState.button2.action,
          button2Color: errorDialogState.button2.color,
        })}
      />
    </>
  );
};

export default LoginValidation;
