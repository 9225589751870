import axios from 'axios';
import {
  GetAnswersPayload,
  GetAnswersResponse,
  GetSchoolFeedingResponse,
  PostAnswersPayload,
} from './dtos/answers.dto';

const BASE_API_URL = process.env.REACT_APP_URL_API ?? '';
const API_URL = BASE_API_URL + '/forms/answers';

export const postAnswers = async (
  payload: PostAnswersPayload,
): Promise<any> => {
  const response = await axios.post<any>(`${API_URL}`, payload);
  return response.data;
};

export const getAnswers = async (
  payload: GetAnswersPayload,
): Promise<GetAnswersResponse> => {
  const response = await axios.get<any>(
    `${API_URL}?page=1&take=10&sortBy=created_at&orderBy=DESC&userAccessUuid=${payload.userAccessUuid}&institutitonUuid=${payload.institutitonUuid}&referencedYear=${payload.referencedYear}`,
  );
  return response.data;
};

export const getAnswersSchoolFeeding = async (
  payload: GetAnswersPayload,
): Promise<GetSchoolFeedingResponse> => {
  const response = await axios.get<any>(
    `${API_URL}?page=1&take=10&sortBy=created_at&orderBy=DESC&userAccessUuid=${payload.userAccessUuid}&institutitonUuid=${payload.institutitonUuid}&referencedYear=${payload.referencedYear}`,
  );
  return response.data;
};

export const putAnswers = async (
  payload: PostAnswersPayload,
  id: string,
): Promise<any> => {
  const response = await axios.put<any>(`${API_URL}/${id}`, payload);
  return response.data;
};
