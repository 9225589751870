import axios from 'axios';
import {
  PostDocumentsPayload,
  PostDocumentsResponse,
} from './dtos/documents.dto';

const BASE_API_URL = process.env.REACT_APP_URL_API ?? '';
const API_URL = BASE_API_URL + '/documents';

export const postDocument = async (
  payload: PostDocumentsPayload,
): Promise<PostDocumentsResponse> => {
  const formData = new FormData();

  formData.append('documentInfo', JSON.stringify(payload.documentInfo));
  formData.append('file', payload.file);

  const response = await axios.post<PostDocumentsResponse>(
    `${API_URL}`,
    formData,
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
  return response.data;
};

export const getDocument = async (uuid: string): Promise<any> => {
  const response = await axios.get(`${API_URL}/${uuid}`, {
    responseType: 'blob',
  });
  return response;
};
