import { criptografar, decriptografar } from 'services/utils/encryption';
import { AuthStatus } from 'contexts/auth/AuthContext';
import { getByIdentificationUser } from 'modules/users/apis/user.api';
import { UserStatusEnum } from 'common/enums';
import { IUser } from 'modules/auth/interfaces/storage.interface';

const TOKEN_KEY = 'mt_login_token';
const TOKEN_API_INTERNA_KEY = 'api_interna_token';
const USER_DATA = 'user_data';

export const authService = {
  getCodeFromUrl(): string | null {
    const params = new URLSearchParams(window.location.search);
    return params.get('code');
  },

  getMtLoginTokeLocalStorage(): string | null {
    try {
      const encryptedToken = localStorage.getItem(TOKEN_KEY);
      return encryptedToken ? JSON.parse(decriptografar(encryptedToken)) : null;
    } catch (error) {
      console.error('Error retrieving token', error);
      this.removeToken();
      return null;
    }
  },

  setMtLoginTokeLocalStorage(token: string): void {
    try {
      localStorage.setItem(TOKEN_KEY, criptografar(JSON.stringify(token)));
    } catch (error) {
      console.error('Error storing token', error);
    }
  },

  setTokenAPIInterna(token: string): void {
    try {
      localStorage.setItem(
        TOKEN_API_INTERNA_KEY,
        criptografar(JSON.stringify(token)),
      );
    } catch (error) {
      console.error('Error storing token api interna', error);
    }
  },

  getTokenAPIInterna(): string | null {
    try {
      const encryptedToken = localStorage.getItem(TOKEN_API_INTERNA_KEY);
      return encryptedToken ? JSON.parse(decriptografar(encryptedToken)) : null;
    } catch (error) {
      console.error('Error retrieving token api interna', error);
      this.removeToken();
      return null;
    }
  },

  getUserDataLocalStorage(): IUser | null {
    try {
      const encryptedData = localStorage.getItem(USER_DATA);
      return encryptedData ? JSON.parse(decriptografar(encryptedData)) : null;
    } catch (error) {
      console.error('Error retrieving user data', error);
      return null;
    }
  },

  setUserDataLocalStorage(userData: IUser): void {
    try {
      localStorage.setItem(USER_DATA, criptografar(JSON.stringify(userData)));
    } catch (error) {
      console.error('Error storing user data', error);
    }
  },

  removeToken(): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_DATA);
  },

  async checkUserStatusByCpf(identification: string): Promise<{
    success: boolean;
    status: AuthStatus;
    userInfo?: IUser;
    message?: string;
  }> {
    try {
      const DadosUsuario: any = await getByIdentificationUser(identification);
      console.log('DadosUsuario : ' + JSON.stringify(DadosUsuario));

      if (DadosUsuario?.isRegisterBlocked) {
        return {
          success: false,
          status: AuthStatus.userBlocked,
          message: 'User is blocked',
        };
      }

      if (!DadosUsuario?.isRegisterActive) {
        return {
          success: false,
          status: AuthStatus.userInactive,
          message: 'User is inactive',
        };
      }

      const { userStatusList } = DadosUsuario;

      const userDataStorage: IUser = {
        ...DadosUsuario,
        statusList: userStatusList,
      };

      return {
        success: true,
        status: AuthStatus.userAuthorized,
        userInfo: userDataStorage,
      };
    } catch (error: any) {
      if (error.response?.data?.statusCode === 404) {
        return {
          success: false,
          status: AuthStatus.userNotFound,
          message: 'User not found',
        };
      }
      return {
        success: false,
        status: AuthStatus.requestError,
        message: 'Request error : ' + error.message,
      };
    }
  },
};
