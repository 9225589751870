import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { postAnswers } from 'api/answers/answers.api';
import {
  FormName,
  MunicipalPartner,
  PostAnswersPayload,
} from 'api/answers/dtos/answers.dto';
import { InstitutionForm } from 'components/Cifa/InstitutionForm';
import { Header } from 'components/Home';
import { ContainerForm } from 'components/Shared/ContainerForm';
import { FormButton, FormButtonType } from 'components/Shared/FormButton';
import { HorizontalStepper } from 'components/Shared/HorizontalStepper';
import { PageTitle } from 'components/Shared/PageTitle';
import { SnackbarFeedback } from 'components/Shared/SnackbarFeedback';
import { TopNavBar } from 'components/TopNavBar';
import { MenuProvider } from 'contexts/menu/MenuContext';
import { ROUTES } from 'routes/constants';

export interface InstitutionI {
  id: number;
  name: string;
}

interface InstitutionData extends InstitutionI {
  data: MunicipalPartner;
}

const CreateCifa2025: React.FC = () => {
  const navigate = useNavigate();

  const [partnerInstitution, setPartnerInstitution] = useState(0);
  const [institutions, setInstitutions] = useState<InstitutionI[]>([]);
  const [
    aterExclusiveServiceMunicipality,
    setAterExclusiveServiceMunicipality,
  ] = useState<number>(0);
  const [municipalPartners, setMunicipalPartners] =
    useState<InstitutionData[]>();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>();

  const handleInstitution = (id: number, name: string) => {
    const onlyIds = institutions.map((institution) => institution.id);

    if (onlyIds.includes(id)) {
      setInstitutions(
        institutions.filter((institution) => institution.id !== id),
      );
    } else {
      setInstitutions([...institutions, { id, name }]);
    }
  };

  const updateMunicipalPartners = (
    value: MunicipalPartner,
    institution: InstitutionI,
  ) => {
    const existingPartnerIndex = municipalPartners?.findIndex(
      (partner) => partner.id === institution.id,
    );

    if (
      existingPartnerIndex !== undefined &&
      existingPartnerIndex >= 0 &&
      municipalPartners
    ) {
      const updatedPartners = [...municipalPartners];
      updatedPartners[existingPartnerIndex] = {
        ...updatedPartners[existingPartnerIndex],
        data: value,
      };
      setMunicipalPartners(updatedPartners);
    } else {
      setMunicipalPartners([
        ...(municipalPartners || []),
        { ...institution, data: value },
      ]);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    const userAccessUuid = localStorage.getItem('userAccessUuid') || '';
    const institutionUuid = localStorage.getItem('institutionUuid') || '';

    const municipalPartnersData =
      municipalPartners?.map((partner) => partner.data) || [];

    const payload: PostAnswersPayload = {
      userAccessUuid,
      institutionUuid,
      formName: FormName.ASSISTENCIA_TECNICA_E_EXTENSAO_RURAL,
      answer: {
        aterExclusiveServiceMunicipality: aterExclusiveServiceMunicipality || 0,
        aterServiceByMunicipalPartner: partnerInstitution === 0 ? false : true,
        aterMunicipalPartnerList: municipalPartnersData,
      },
      referencedYear: 2025,
    };

    try {
      await postAnswers(payload);
      navigate(ROUTES.CREATE_FEDERAL_SCHOOL_FEEDING_2025);
    } catch (error) {
      setErrorMessage(
        'Erro ao enviar os dados da Assistência Técnica e Extensão Rural.',
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <MenuProvider>
      <TopNavBar />

      <Header title='CIFA - Cadastrar Município - 2025' showBackButton={true} />

      <HorizontalStepper activeStep={0} />

      <PageTitle
        title='Assistência Técnica e Extensão Rural'
        subtitle='Informe corretamente os dados sobre os atendimentos ATER realizados no Município.'
      />

      <Stack marginX='4rem'>
        <Alert
          severity='info'
          sx={{ marginY: '2rem', backgroundColor: '#EAEFF9' }}
        >
          <Typography
            variant='body2'
            fontSize='14px'
            textAlign='left'
            lineHeight={1.5}
          >
            Considera-se Assistência Técnica e Extensão Rural - ATER o número de
            atendimentos prestados aos beneficiários da agricultura familiar
            (unidade agro familiar) do município por profissional de ATER, por
            meio de, no mínimo, 1 (uma) visita presencial ao longo do ano.
          </Typography>
        </Alert>

        <ContainerForm title='ATER - Atendidos exclusivamente pelo Município'>
          <TextField
            id='beneficiarios-exclusivamente-municipio'
            label='Quantos beneficiários da Agricultura Familiar (unidade agro familiar) foram atendidos com ATER realizada exclusivamente pelo Município? (Opcional)'
            variant='outlined'
            value={aterExclusiveServiceMunicipality}
            onChange={(e) =>
              setAterExclusiveServiceMunicipality(
                Number(e.target.value.replace(/\D/g, '')),
              )
            }
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
          />
        </ContainerForm>

        <ContainerForm title='ATER - Atendidos por Instituições parceiras do Município'>
          <FormControl>
            <FormLabel id='foreigner-label'>
              O Município realizou parceria com uma instituição executora de
              Assistência Técnica e Extensão Rural - ATER em 2024? (Opcional)
            </FormLabel>

            <RadioGroup
              aria-labelledby='foreigner-label'
              defaultValue={partnerInstitution}
              name='radio-buttons-group'
              value={partnerInstitution}
              onChange={(e) => setPartnerInstitution(Number(e.target.value))}
            >
              <FormControlLabel value={0} control={<Radio />} label='Não' />
              <FormControlLabel value={1} control={<Radio />} label='Sim' />
            </RadioGroup>
          </FormControl>

          {partnerInstitution === 1 && (
            <FormGroup>
              <FormLabel id='foreigner-label'>
                Informe a(s) Instituição(ões) em que foi realizada parceria:
              </FormLabel>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={institutions
                      .flatMap((institution) => institution.id)
                      .includes(1)}
                    onChange={() => handleInstitution(1, 'EMPAER MT')}
                  />
                }
                label='EMPAER MT'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={institutions
                      .flatMap((institution) => institution.id)
                      .includes(2)}
                    onChange={() =>
                      handleInstitution(2, 'Instituições de Ensino')
                    }
                  />
                }
                label='Instituições de Ensino'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={institutions
                      .flatMap((institution) => institution.id)
                      .includes(3)}
                    onChange={() => handleInstitution(3, 'Organizações - ONGs')}
                  />
                }
                label='Organizações - ONGs'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={institutions
                      .flatMap((institution) => institution.id)
                      .includes(4)}
                    onChange={() =>
                      handleInstitution(4, 'Instituições Privadas/Outros')
                    }
                  />
                }
                label='Instituições Privadas/Outros'
              />
            </FormGroup>
          )}
        </ContainerForm>

        {institutions.map((institution) => (
          <InstitutionForm
            key={institution.id}
            institution={institution}
            setInstituion={(value) =>
              updateMunicipalPartners(value, institution)
            }
          />
        ))}

        <Stack
          direction={{ md: 'row-reverse' }}
          marginY='2rem'
          sx={{ gap: '1rem' }}
        >
          <FormButton
            title='Avançar'
            type={FormButtonType.PRIMARY}
            onClick={handleSubmit}
            isDisabled={loading}
          />
          <FormButton
            title='Voltar'
            type={FormButtonType.SECONDARY}
            onClick={() => navigate(ROUTES.CIFA2025)}
          />
        </Stack>

        {errorMessage && (
          <SnackbarFeedback isOpen type='error' message={errorMessage} />
        )}
      </Stack>
    </MenuProvider>
  );
};

export default CreateCifa2025;
