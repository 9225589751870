import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface CustomAccordionProps {
  accordionSummaryText: string;
  children: React.ReactNode;
  style?: SxProps<Theme>;
}

const CustomAccordion: React.FC<CustomAccordionProps> = ({
  accordionSummaryText,
  children,
  style,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Accordion sx={style} defaultExpanded>
        <AccordionSummary onClick={handleIsOpen}>
          <IconButton
            onClick={handleIsOpen}
            sx={{
              marginRight: '10px',
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s ease-in-out',
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
          <Typography variant='h6'>{accordionSummaryText}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </>
  );
};

export default CustomAccordion;
